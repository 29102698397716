export const environment = {
  production: true,
  hmr: false,
  showConsoleLog: false,
  apiUrl: 'https://ecmsapi.judiciarysca.gm.gov.ng/api',
  courtTitle: 'Sharia Court of Appeal',
  courtTitleSentence: 'Sharia Court of Appeal',
  stateTitle: 'Gombe State',
  stateTitleSentence: 'Gombe State',
  currentDivision: '',
  deptTitle: 'PROBATE DEPARTMENT',
  BASE_URL: 'https://ecmsapi.judiciarysca.gm.gov.ng/api',
  BASE_URL_II: 'https://ecmsapi.judiciarysca.gm.gov.ng/api',
  API_URL: '/',
  PORTAL_URL: 'https://portal.judiciarysca.gm.gov.ng',
  currentState: 'SHARIA COURT OF APPEAL GOMBE STATE',
  court_bg_path: 'assets/img/court-bg.jpg',
  court_logo_path: 'assets/img/gombe-sharia-white-bg.png',
  court_logo_1: 'assets/img/coat-of-arms-tp.png',
  court_logo_2: 'assets/img/gombe-sharia-bg-new.jpg',
  paystack_public_key: 'pk_test_8046635943f7c19ac083706dd92dc2a6c5d53d68',
  // paystack_public_key: 'pk_live_bc914d50fe669858c511bb882914d225532b05b9',
  remitta_api_key: 'RkhDfDEzOTMwMjY1MzYyfDczOTdmMTIyZTJiYWFhNDUxYTUwNjJmOTkxYTBmOTlhOWJiOGY2OGRmMDVhNWE1MTYyZmI5MjRlYTllYTE1ZjcyYzQzMDRiNTJmMDhlMTdiNWY0ZGY1NDgyNTE0MjRlNTI5NWZiM2UxZDZmNzBjM2E5ZDQ0ZWFiOTg4ZGFiNTA4',
  remitta_mode: 'live',
  // remitta_api_key: 'QzAwMDAyNTE5MzV8NTcwODYyNDl8NTk0ZGFkZTE2OWM3N2UyZTMyMzlhYWJiZjM4NjdiZThhYWQyMTUxMWVlMjNiMjdjYmFmODVlNTZlMDFlNTA5ZGZjNDVhOTliM2Q2OGNhZmE0YmI3YzllODBhMTdmNmIxMDc4ZWRlMWM5MDc1OTc4ZGYxMDQ5ZjIxYWU2Mjc0YjQ=',
  // remitta_mode: 'test',
  interswitch_mechant_code: 'VNA',
  // interswitch_mechant_code: 'MX128354',
  interswitch_pay_item: '103',
  // interswitch_pay_item: 'Default_Payable_MX128354',
  interswitch_mode: 'TEST',
  currentCourtInfoEmail: 'info@judiciarysca.gm.gov.ng',
  showJudiciarySuffix: false,
  showState: true,
  showDivision: false,
  showSupCourtTitle: true,
  supCourtTitle:'The Judiciary,',
  supCourtTitleColor:'red'
};
